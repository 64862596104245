import { getNumeralDeclension } from '@glow/shared/utils/format';

export function formatMasterCount(count: number) {
  const declension = getNumeralDeclension(count, [
    'мастер',
    'мастера',
    'мастеров',
  ]);
  return `${count} ${declension}`;
}

export function formatExperienceRecordRange(
  startYear: number,
  endYear: number
) {
  const currentYear = new Date().getFullYear();
  let result = startYear.toString();
  if (endYear === startYear) result += ` г.`;
  else if (endYear >= currentYear) result += ` г. — наст. время`;
  else result += ` — ${endYear} гг.`;
  return result;
}
